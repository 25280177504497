import Cookies from "universal-cookie";
const cookies = new Cookies();

const axios = require("axios");

const { REACT_APP_API_BASE_URL } = process.env;

const axiosInstance = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
});
function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = cookies.get("d_token");
    const api_key = cookies.get("d_key");
    const decode_key = window.atob(api_key);
    if (token) {
      config.headers["X-API-KEY"] = decode_key;
      config.headers["Authorization"] = "Bearer " + token;
    }
    const isTokenExpired = () => {
      const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
      const decoded = token && parseJwt(token);

      return decoded.exp < currentTimestampInSeconds ? true : false;
    };
    const checkToken = isTokenExpired();

    if (checkToken) {
      const refresh_token = cookies.get("d_refresh_token");
      const responseData = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/auth/refreshToken`,
        { refresh_token }
      );
      cookies.set("d_token", responseData?.data?.accessToken);
      cookies.set("d_refresh_token", responseData?.data?.refreshToken);
      config.headers["Authorization"] =
        "Bearer " + responseData?.data?.accessToken;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// axiosInstance.interceptors.response.use(function (res) {
//   return res;
// }, function (error) {
//   const originalRequest = error.config
//   // console.log("originalRequest:::", originalRequest);

//   if (error.response.status === 401 && originalRequest.url.endsWith('/refresh-token')) {
//     cookies.remove('cs_token');
//     cookies.remove('cs_user');
//     cookies.remove('cs_retoken');
//     window.location = '/';
//     return Promise.reject(error);
//   }

//   if (error.response.status === 401 && !originalRequest._retry) {
//     originalRequest._retry = true;
//     const refreshToken = cookies.get('cs_retoken');

//     return axiosInstance.post(
//       '/refresh-token',
//       {
//         refresh_token: refreshToken
//       })
//       .then(res => {
//         if (res.status === 200) {
//           cookies.set('cs_token', res.data.accessToken);
//           originalRequest.headers['Authorization'] = 'Bearer ' + res.data.accessToken;
//           return axios(originalRequest);
//         }
//       })
//   }
//   return Promise.reject(error);
// });

export default axiosInstance;
