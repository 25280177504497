// import './overview.css'

import clsx from 'clsx';
import DateTimePicker from 'react-datetime-picker';
import { DataGridPro } from '@mui/x-data-grid-pro';
// import { makeStyles } from '@mui/styles';
import { DashboardOutlined, Info, Clear } from '@mui/icons-material';
import { Container, Paper, Grid, Button, InputLabel, InputAdornment, IconButton, OutlinedInput, FormControl, Tooltip, Select, Menu, MenuItem, Box, TextField } from '@mui/material';

import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

// import CustomPopup from '../Popup/CustomPopup';
import logo from '../images/logo.svg';
import { Link } from 'react-router-dom';
import { useState, useEffect, useCallback, useDeferredValue } from 'react';
import axios from '../../helpers/request';
import moment from 'moment';

import styled from '@emotion/styled'
import { css } from '@emotion/react'

const MainContainer = styled.main(({ theme }) => ({
  flexGrow: 1,
  height: '100vh',
  overflow: 'auto',
  padding: theme.spacing(2)
}))

const InnerContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}))

const ActionButton = styled(Button)(({ theme }) => (theme.actionButton))

const Toolbar = styled.div(({ theme }) => (theme.toolbar));

const CustomToolTip = styled(Tooltip)(({ theme }) => ({
  maxWidth: 100,
  verticalAlign: 'middle',
  padding: '5px',
}))

const CustomPaper = styled(Paper)(({ theme, ...props }) => ({
  padding: props?.halfPadding == 'true' ? theme.spacing(0.75) :theme.spacing(1),
  display: 'flex',
  overflow: 'auto',
  flexDirection: 'column',
  '& label': {
    lineHeight: '1.1876em',
    letterSpacing: '0.00938em',
    fontSize: '1rem'
  },
  '& svg': {
    fontSize: '1rem',
    color: theme.palette.primary.main,
    verticalAlign: 'initial',
    padding: 0
  },
  '& .paperButton': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    borderRadius: '10px',
    whiteSpace: 'nowrap',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    }
  },
  '& .largeLabel': {
    textOverflow: 'ellipsis',
    width: '70%',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  '& .customRowTable': {
    '& .meeting-archive--true': {
      backgroundColor: theme.palette.background.red,
    },
  },
  '& .Flip': {
    '& .MuiDataGrid-window': {
      transform: 'rotateX(180deg)',
      top: 'auto !important'
    },
    '& .MuiDataGrid-dataContainer': {
      transform: 'rotateX(180deg)'
    },
    height: 'calc(100vh - 70px)',
    display: 'flex'
  },
  '& FormControl': {
    width: '85%'
  },
  '& .react-datetime-picker__calendar': {
    zIndex: 2
  },
  '& .react-calendar': {
    position: 'fixed'
  },
  '& .react-datetime-picker__inputGroup__input:invalid': {
    background: 'transparent'
  },
  '& .react-datetime-picker__wrapper': {
    // height: '56px',
    lineHeight: '1.5',
    // padding: '18.5px 14px',
    padding: theme.spacing(1),
    borderRadius: '4px',
    border: '1px solid rgba(0, 0, 0, 0.23)'
  },
  '& .react-datetime-picker__button': {
    padding: '0px 0px 0px 4px',
    opacity: '0.5'
  },
  '& .react-calendar__navigation__arrow': {
    fontSize: 'xx-large'
  }
}))

const MultiColumnCustomPaper = styled(Paper)(({ theme, ...props }) => ({
  padding: props?.halfPadding == 'true' ? theme.spacing(0.75) : theme.spacing(1),
  display: 'flex',
  overflow: 'auto',
  flexDirection: 'row',
  justifyContent: 'space-between',
  '& label': {
    lineHeight: '1.1876em',
    letterSpacing: '0.00938em',
    fontSize: '1rem'
  },
  '& svg': {
    fontSize: '1rem',
    color: theme.palette.primary.main,
  },
  '& .paperButton': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    borderRadius: '10px',
    whiteSpace: 'nowrap',
    textTransform: 'none',
    margin: '10px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    }
  },
  '& .largeLabel': {
    // fontSize: '12px',
    textOverflow: 'ellipsis',
    width: '70%',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  '& FormControl': {
    width: '85%'
  },
  '& .react-calendar': {
    position: 'fixed'
  },
  '& .react-datetime-picker__inputGroup__input:invalid': {
    background: 'transparent'
  },
  '& .react-datetime-picker__wrapper': {
    // height: '56px',
    // lineHeight: '1.1876em',
    // padding: '18.5px 14px',
    padding: theme.spacing(1),
    borderRadius: '4px',
    border: '1px solid rgba(0, 0, 0, 0.23)'
  },
  '& .react-datetime-picker__button': {
    padding: '0px 0px 0px 4px',
    opacity: '0.5'
  },
  '& .react-calendar__navigation__arrow': {
    fontSize: 'xx-large'
  },
  '& .paperBox': {
    display: 'flex',
    flexDirection: 'column',
    width: '85%'
  },
  '& .buttonBox': {
    '& .paperButton': {
      margin: '20px 0px 0px 10px'
    }
  },
  '& .MuiFormControl-root': {
    width: '85%'
  }
}))

// const useStyles = makeStyles((theme) => ({
//   content: {
//     flexGrow: 1,
//     height: '100vh',
//     overflow: 'auto',
//     padding: theme.spacing(2)
//   },
//   container: {
//     paddingTop: theme.spacing(4),
//     paddingBottom: theme.spacing(4),
//   },
//   toolbar: theme.toolbar,
//   paper: {
//     padding: theme.spacing(1),
//     display: 'flex',
//     overflow: 'auto',
//     flexDirection: 'column',
//     '& label': {
//       lineHeight: '1.1876em',
//       letterSpacing: '0.00938em',
//       fontSize: '1rem'
//     },
//     '& svg': {
//       fontSize: '1rem',
//       color: theme.palette.primary.main,
//     },
//     '& .paperButton': {
//       backgroundColor: theme.palette.primary.main,
//       color: theme.palette.secondary.main,
//       borderRadius: '10px',
//       whiteSpace: 'nowrap',
//       textTransform: 'none',
//     },
//     '& .largeLabel': {
//       // fontSize: '12px',
//       textOverflow: 'ellipsis',
//       width: '70%',
//       whiteSpace: 'nowrap',
//       overflow: 'hidden'
//     },
//     '& .customRowTable': {
//       '& .meeting-archive--true': {
//         backgroundColor: theme.palette.background.red,
//       },
//     },
//     '& .Flip': {
//       '& .MuiDataGrid-window': {
//         transform: 'rotateX(180deg)',
//         top: 'auto !important'
//       },
//       '& .MuiDataGrid-dataContainer': {
//         transform: 'rotateX(180deg)'
//       }
//     },
//     '& FormControl': {
//       width: '85%'
//     }
//   },
//   multipleColumn: {
//     flexDirection: 'row',
//     justifyContent: 'space-between',
//     '& .paperButton': {
//       margin: '10px'
//     },
//     '& .paperBox': {
//       display: 'flex',
//       flexDirection: 'column',
//       width: '85%'
//     },
//     '& .buttonBox': {
//       '& .paperButton': {
//         margin: '20px 0px 0px 10px'
//       }
//     },
//     '& .MuiFormControl-root': {
//       width: '85%'
//     }
//   },
//   fixedHeight: {
//     height: 240,
//   },
//   customDatepicker: {
//     padding: theme.spacing(1),
//     '& .react-calendar': {
//       position: 'fixed'
//     },
//     '& .react-datetime-picker__inputGroup__input:invalid': {
//       background: 'transparent'
//     },
//     '& .react-datetime-picker__wrapper': {
//       // height: '56px',
//       // lineHeight: '1.1876em',
//       // padding: '18.5px 14px',
//       padding: theme.spacing(1),
//       borderRadius: '4px',
//       border: '1px solid rgba(0, 0, 0, 0.23)'
//     },
//     '& .react-datetime-picker__button': {
//       padding: '0px 0px 0px 4px',
//       opacity: '0.5'
//     },
//     '& .react-calendar__navigation__arrow': {
//       fontSize: 'xx-large'
//     }
//   },
//   toolTip: {
//     maxWidth: 100,
//     verticalAlign: 'middle',
//     padding: '5px',
//   },
//   actionButton: theme.actionButton,
// }));

const mavenStatusValues = [
  { title: 'Active', value: 'Active' },
  { title: 'Finished', value: 'Finished' },
  { title: 'Cancelled', value: 'Cancelled' },
  { title: 'Deleted', value: 'Deleted' },
]

export default function Overview() {
  // const classes = useStyles();
  // const multipleColumnPaper = clsx(classes.paper, classes.customDatepicker, classes.multipleColumn);
  // const customDatePaper = clsx(classes.paper, classes.customDatepicker);

  const listSortModel = {
    sortModel: [],
  }

  const [data, setData] = useState({
    data: [],
    total: 0
  });

  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [formDate, setFormDate] = useState('');
  const [toDate, setToDate] = useState('');
  // const [isOpenDateFilter, setIsOpenDateFilter] = useState(false);
  // const [isAppliedDateFilter, setIsAppliedDateFilter] = useState(false);
  const [maven_status, setMavenStatus] = useState('all');
  const [queryOptions, setQueryOptions] = useState(listSortModel);

  const deferredSearch = useDeferredValue(search)

  const getLogs = () => {
    setLoading(true);
    setData({
      data: [],
      total: 0
    })
    let searchParams = {
      page_number: pageNumber,
      page_size: pageSize,
      search: deferredSearch,
      maven_status: maven_status === "all" ? "" : maven_status
    };

    if (queryOptions.sortModel[0] && queryOptions.sortModel[0].sort != 'null') {
      searchParams.order_by = queryOptions.sortModel[0].field + " " + queryOptions.sortModel[0].sort
    }

    if (formDate && toDate) {
      searchParams.from_date = moment(formDate).format('YYYY-MM-DD')
      searchParams.to_date = moment(toDate).format('YYYY-MM-DD')
    }

    axios.get('/api/meeting', { params: searchParams }).then(async (response) => {
      setLoading(false);
      if (response && response.status) {
        setData(response.data)
      } else {
        throw new Error(response?.error);
      }
    });
  }

  // useEffect(() => {
  //   if (formDate && toDate) {
  //     // console.log("isOpenDateFilter");
  //     // setFormDate('');
  //     // setToDate('');
  //     // setIsAppliedDateFilter(true);
  //   } else if(!formDate && !toDate) {
  //     // setIsAppliedDateFilter(false);
  //   }
  // }, [formDate, toDate])

  useEffect(() => {
    getLogs()
  }, [pageSize, pageNumber, deferredSearch, formDate, toDate, maven_status, queryOptions])

  const onChangePageSize = (pageSize) => {
    setPageSize(pageSize);
  }

  const onPageChange = (pageNumber) => {
    setPageNumber(pageNumber + 1);
  }

  const onChangeSearch = (searchValue) => {
    setSearch(searchValue.trim());
    setPageNumber(1);
  }

  const onChangeStatusFilter = (event) => {
    const value = event.target.value;
    setMavenStatus(value);
    setPageNumber(1);
  }

  const handleFromDateChange = (value) => {
    setFormDate(value)
  }
  const handleToDateChange = (value) => {
    setToDate(value)
  }

  const handleSortModelChange = useCallback((sortModel) => {
    console.log("handleSortModelChange", sortModel)
    // Here you save the data you need from the sort model
    setQueryOptions({ sortModel: [...sortModel] });
  }, []);

  const columns = [
    { field: 'topic', headerName: 'Topic', width: 200, sortable: false, filterable: false },
    { field: 'maven_id', headerName: 'Maven ID', width: 200, sortable: false, filterable: false },
    { field: 'source', headerName: 'Maven Source', width: 200, sortable: false, filterable: false },
    {
      field: 'meeting_id', headerName: 'Twilio ID', width: 300, sortable: false, filterable: false,
      renderCell: (params) => {
        return (
          <Link to={'/interaction/' + params.row.meeting_id}>
            {params.row.meeting_id}
          </Link>
        )
      }
    },
    { field: 'maven_status', headerName: 'Status', width: 110, sortable: false },
    {
      field: 'datetime', headerName: 'Meeting Date', width: 150, sortable: true, type: 'date', filterable: false,
      valueGetter: ({ value }) => value && moment(value).format('DD/MM/YYYY'), options: { sortDirection: 'desc' },
    },
    {
      field: 'started_at', headerName: 'Start Time', width: 150, sortable: false, filterable: false,
      valueGetter: ({ value }) => value ? moment(value).format('HH:mm') : 'NA'
    },
    {
      field: 'ended_at', headerName: 'End Time', width: 150, sortable: false, filterable: false,
      renderCell: (params) => params.row.ended_at ? params.row.meeting_ended_at ? moment(params.row.meeting_ended_at).format('HH:mm') : moment(params.row.ended_at).format('HH:mm') : 'NA',
    },
    {
      field: 'duration', headerName: 'Duration (Minutes)', width: 110, sortable: false, filterable: false,
      renderCell: (params) => {
        return (
          <span>
            {(params.row.started_at && params.row.ended_at) ? params.row.meeting_ended_at ? Math.ceil(moment(params.row.meeting_ended_at).diff(moment(params.row.started_at), 'minutes', true)) : Math.ceil(moment(params.row.ended_at).diff(moment(params.row.started_at), 'minutes', true)) : 'NA'}
          </span>
        )
      }
    },
    {
      field: 'record_meeting', headerName: 'Recording', width: 110, sortable: false, filterable: false,
      renderCell: (params) => {
        return (
          <>
            {params.row.record_meeting ? <b>Yes</b> : 'No'}
          </>
        )
      }
    },
    {
      field: 'is_anonymous', headerName: 'Anonymous', width: 110, sortable: false, filterable: false,
      renderCell: (params) => {
        return (
          <>
            {params.row.is_anonymous ? <b>Yes</b> : 'No'}
          </>
        )
      }
    },
    {
      field: 'show_disclaimer', headerName: 'Disclaimer', width: 110, sortable: false, filterable: false,
      renderCell: (params) => {
        return (
          <>
            {params.row.show_disclaimer ? <b>Yes</b> : 'No'}
          </>
        )
      }
    },
    { field: 'participant_count', headerName: 'Participants', width: 100, sortable: false, filterable: false },
    // { field: 'phone', headerName: 'Phone', width: 120, sortable: false },
    // { field: 'voip', headerName: 'VOIP', width: 80, sortable: false },
    // { field: 'video', headerName: 'Video', width: 80, sortable: false },
    // { field: 'screensharing', headerName: 'Screen Sharing', width: 140, sortable: false },
    // {
    //   field: 'action', headerName: 'Action', width: 180, sortable: false,
    //   renderCell: (params) => {
    //     return (
    //       <div className='userListActions'>
    //         <Link to={'/meeting/' + params.row.meeting_id}>
    //           <button className="actionElementButton">
    //             <span>View Meeting</span>
    //           </button>
    //         </Link>
    //       </div>
    //     )
    //   }
    // },
    {
      field: 'link', headerName: 'Live Meeting', width: 310, sortable: false, filterable: false,
      renderCell: (params) => {
        return (
          <>
            {
              (params.row.started_at && !params.row.ended_at)
                ?
                <>
                  <ActionButton href={params.row.join_link} target="_blank" >
                    Join Meeting
                  </ActionButton>
                  <ActionButton href={params.row.chaperoning_link} target="_blank" >
                    Join As Chaperone Mode
                  </ActionButton>
                </>
                :
                ""
            }
          </>
        )
      }
    }
  ];

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAllClear = () => {
    setMavenStatus('all');
    setSearch('');
    setFormDate('');
    setToDate('');
    setPageNumber(1);
  }

  const meetingExcelHeaderRow = [['Topic', 'Maven ID', 'Twilio ID', 'Status', 'Interaction Date', 'Start Time (UTC)', 'End Time (UTC)', 'Duration', 'Recording', 'Anonymous Interaction', 'Disclaimer', 'Participants']];

  const getMeetingExcelData = (meetingData) => {
    const dataRows = meetingData.map(({ topic, maven_id, meeting_id, maven_status, started_at, ended_at, record_meeting, is_anonymous, show_disclaimer, participant_count, meeting_ended_at }) => (
      [topic, maven_id, meeting_id, maven_status, started_at ? moment(started_at).format('DD/MM/YYYY') : 'NA', started_at ? moment(started_at).format('HH:mm') : 'NA', ended_at ? moment(ended_at).format('HH:mm') : 'NA', (started_at && ended_at) ? meeting_ended_at ? Math.ceil(moment(meeting_ended_at).diff(moment(started_at), 'minutes', true)) : Math.ceil(moment(ended_at).diff(moment(started_at), 'minutes', true)) : 'NA', record_meeting ? 'Yes' : 'No', is_anonymous ? 'Yes' : 'No', show_disclaimer ? 'Yes' : 'No', participant_count]
    ))

    return [...meetingExcelHeaderRow, ...dataRows];
  }

  const exportMeetingInteraction = (meetingData) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = ".xlsx";
    const headerCellBackgroundColor = "4A86E8";
    const headerCellFontColor = "FFFFFF";
    const maxColCounts = meetingExcelHeaderRow[0].length + 65; // Considering columns will not exceeds than Z (for A-Z columns)

    const ws = XLSX.utils.aoa_to_sheet(getMeetingExcelData(meetingData));

    for (let i = 65; i < maxColCounts; i++) {
      ws[`${String.fromCharCode(i) + 1}`].s = { fill: { patternType: "solid", fgColor: { rgb: headerCellBackgroundColor }, bgColor: { rgb: headerCellBackgroundColor } }, font: { color: { rgb: headerCellFontColor } } };
    }

    // console.log("ws", ws);
    const wb = { Sheets: { 'meetings': ws }, SheetNames: ['meetings'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `meetings_${new Date().getTime()}${fileExtension}`);

    setAnchorEl(null);
  };

  const participantExcelHeaderRow = [['Topic', 'Maven ID', 'Twilio ID', 'Participant Display Name', 'Mode', 'Join Time (UTC)', 'Leave Time (UTC)', 'Joined Via', 'OS', 'Browser', 'Location', 'Video Permission', 'Audio Permission', 'Screen Sharing', 'Kill Switch used']];

  const getParticipantExcelData = (participantData) => {
    // const headerRow = [['Topic', 'Maven ID', 'Twilio ID', 'Participant Display Name', 'Mode', 'Join Time (UTC)', 'Leave Time (UTC)', 'Joined Via', 'OS', 'Location', 'Video', 'Audio', 'Screen', 'Kill Switch']];

    const dataRows = participantData.map(({ topic, maven_id, meeting_id, identity, join_time, leave_time, join_method, device, browser, location, video, audio, screen_share, kill_switch_used }) => (
      [topic, maven_id, meeting_id, identity ? identity.startsWith('+') ? identity.split('-')[0] : identity.split('###')[0] : '', identity ? (identity.split('###')[0] === "Chaperoning Mode" || identity.split('###')[1].startsWith('CP##')) ? "Chaperone" : "Normal" : '', join_time ? moment(join_time).format('HH:mm') : 'NA', leave_time ? moment(leave_time).format('HH:mm') : 'NA', join_method, device, browser, location, video ? 'Yes' : 'No', audio ? 'Yes' : 'No', screen_share ? 'Yes' : 'No', kill_switch_used ? 'Yes' : identity ? (identity.split('###')[0] === "Chaperoning Mode" || identity.split('###')[1].startsWith('CP##')) ? 'No' : '-' : '-']
    ))

    return [...participantExcelHeaderRow, ...dataRows];
  }

  const exportParticipantInteraction = async (meetingData) => {
    const meetingIds = meetingData.map(({ meeting_id }) => (meeting_id))

    try {
      const response = await axios.post('/internal/meeting-participants', { meeting_ids: meetingIds });

      if (response.status === 200) {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = ".xlsx";
        const headerCellBackgroundColor = "4A86E8";
        const headerCellFontColor = "FFFFFF";
        const maxColCounts = participantExcelHeaderRow[0].length + 65; // Considering columns will not exceeds than Z (for A-Z columns)

        const ws = XLSX.utils.aoa_to_sheet(getParticipantExcelData(response.data));
        for (let i = 65; i < maxColCounts; i++) {
          ws[`${String.fromCharCode(i) + 1}`].s = { fill: { patternType: "solid", fgColor: { rgb: headerCellBackgroundColor }, bgColor: { rgb: headerCellBackgroundColor } }, font: { color: { rgb: headerCellFontColor } } };
        }
        // console.log("ws", ws);
        const wb = { Sheets: { 'participants': ws }, SheetNames: ['participants'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `participants_${new Date().getTime()}${fileExtension}`);

      }
      setAnchorEl(null);
    } catch (error) {
      alert("Unable to import data");
      setAnchorEl(null);
    }
  };

  return (
    <MainContainer >

      <Toolbar >
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <div className="title">
          <span className='headerText'>
            <DashboardOutlined fontSize="large" className='headerIcon' /> Meetings
          </span>
        </div>
      </Toolbar>

      <InnerContainer maxWidth="xl" >
        <Grid container spacing={1}>
          <Grid item xs={12} md={3} lg={3} xl={3}>
            <CustomPaper halfPadding='true'>
              <label>
                From Date <span className="mandatory">*</span>
                {/* classes={{ tooltip: classes.toolTip }} */}
                <CustomToolTip
                  title="Both From Date and To Date are required to filter records"
                  placement="top"
                >
                  <Info sx={{ fontSize: 8 }} />
                </CustomToolTip>
              </label>
              <DateTimePicker
                name='fromDate'
                value={formDate}
                onChange={handleFromDateChange}
                format='dd/MM/y'
                className='datepicker'
                disableClock
                required
                monthPlaceholder="MM"
                dayPlaceholder="DD"
                yearPlaceholder="YYYY"
              />
            </CustomPaper>
          </Grid>
          <Grid item xs={12} md={3} lg={3} xl={3}>
            <MultiColumnCustomPaper halfPadding='true' >
              <Box className='paperBox'>
                <label>To Date <span className="mandatory">*</span></label>
                <DateTimePicker
                  name='toDate'
                  value={toDate}
                  onChange={handleToDateChange}
                  format='dd/MM/y'
                  className='datepicker'
                  disableClock
                  required
                  monthPlaceholder="MM"
                  dayPlaceholder="DD"
                  yearPlaceholder="YYYY"
                />
              </Box>
              <Box className='buttonBox'>
                <Button
                  className='paperButton'
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  Export
                </Button>
              </Box>
            </MultiColumnCustomPaper>
          </Grid>
          <Grid item xs={12} md={3} lg={3} xl={3}>
            <CustomPaper >
              <TextField
                label="Topic/MeetingID/MavenID"
                id="outlined-start-adornment"
                value={search}
                onChange={(event) => { onChangeSearch(event.target.value) }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => { onChangeSearch('') }}
                    >
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                }}
              />
              {/* <FormControl variant="outlined">
                <InputLabel htmlFor="standard-adornment-password">Topic/MeetingID/MavenID</InputLabel>
              <OutlinedInput
                id="standard-adornment-password"
                type='text'
                value={search}
                onChange={(event) => { onChangeSearch(event.target.value) }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => { onChangeSearch('') }}
                    >
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl> */}
            </CustomPaper>
          </Grid>
          <Grid item xs={12} md={3} lg={3} xl={3}>
            <MultiColumnCustomPaper >
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => exportMeetingInteraction(data.data)}>Interaction Report</MenuItem>
                <MenuItem onClick={() => exportParticipantInteraction(data.data)}>Participant Report</MenuItem>
              </Menu>
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-age-native-simple">Status</InputLabel>
                <Select
                  native
                  value={maven_status}
                  onChange={onChangeStatusFilter}
                  label="Status"
                >
                  <option value="all">All</option>
                  {mavenStatusValues.map(option =>
                    <option key={option.value} value={option.value}>{option.title}</option>
                  )}
                </Select>
              </FormControl>
              <Button
                className='paperButton'
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleAllClear}
              >
                Clear All
              </Button>
            </MultiColumnCustomPaper>
          </Grid>

          <Grid item xs={12}>
            <CustomPaper >
              <div className='customRowTable'>
                <div className='Flip'>
                  <DataGridPro
                    getRowId={(row) => row.meeting_id}
                    columns={columns}
                    rows={data.data}
                    rowCount={data.total}
                    pageSize={pageSize}
                    rowsPerPageOptions={[10, 25, 50]}
                    onPageSizeChange={(newPageSize) => onChangePageSize(newPageSize)}
                    onPageChange={(newPage) => onPageChange(newPage)}
                    autoHeight={false}
                    pagination
                    paginationMode="server"
                    loading={loading}
                    getRowClassName={(params) =>
                      `meeting-archive--${params.row.is_deleted}`
                    }
                    initialState={{
                      sorting: listSortModel
                    }}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                  />
                </div>
              </div>
            </CustomPaper>
          </Grid>

        </Grid>
      </InnerContainer>

      {/* <div className='overview'>

      <div className="pageHeader">
        <div className="headerLeft">
          <img src={logo} alt="" />
        </div>
        <div className="headerRight">
          <span className="pageHeaderTitle">
            <DashboardOutlined className='pageHeaderIcon' /> Meetings
          </span>
        </div>
      </div>

      <Grid container spacing={3}>
        <Grid item xs='auto' sm='auto' md={6}>
          <div className='datefields'>
            <Tooltip title="Both From Date and To Date are required to filter records" classes={{ tooltip: classes.toolTip }} className='info' placement="top">
              <Info />
            </Tooltip>

            <label>From Date <span className="mandatory">*</span></label>
            <DateTimePicker
              name='fromDate'
              value={formDate}
              onChange={handleFromDateChange}
              format='y/MM/dd'
              className='datepicker'
              disableClock
              required
              monthPlaceholder="mm"
              dayPlaceholder="dd"
              yearPlaceholder="yyyy"
            />

            <label>To Date <span className="mandatory">*</span></label>
            <DateTimePicker
              name='toDate'
              value={toDate}
              onChange={handleToDateChange}
              format='y/MM/dd'
              className='datepicker'
              disableClock
              required
              monthPlaceholder="mm"
              dayPlaceholder="dd"
              yearPlaceholder="yyyy"
            />

            <Button className='headerButton' aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
              Export
            </Button>

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => exportMeetingInteraction(data.data)}>Interaction Report</MenuItem>
              <MenuItem onClick={() => exportParticipantInteraction(data.data)}>Participant Report</MenuItem>
            </Menu>
          </div>
        </Grid>
        <Grid item xs='auto' sm='auto' md={2} className='searchBox'>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Status</InputLabel>
            <Select
              native
              value={maven_status}
              onChange={onChangeStatusFilter}
              label="Status"
            >
              <option value="all">All</option>
              {mavenStatusValues.map(option =>
                <option key={option.value} value={option.value}>{option.title}</option>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs='auto' sm='auto' md={4} className='searchBox'>
          <FormControl variant="outlined">
            <InputLabel htmlFor="standard-adornment-password">Search Topic or Meeting ID</InputLabel>
            <OutlinedInput
              id="standard-adornment-password"
              type='text'
              value={search}
              labelWidth={200}
              onChange={(event) => { onChangeSearch(event.target.value) }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => { onChangeSearch('') }}
                  >
                    <Clear />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      </Grid>

      <div className={classes.customRowTable}>
        <div className='Flip'>
          <DataGrid
            getRowId={(row) => row.meeting_id}
            columns={columns}
            rows={data.data}
            rowCount={data.total}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 25, 50]}
            onPageSizeChange={(newPageSize) => onChangePageSize(newPageSize)}
            onPageChange={(newPage) => onPageChange(newPage)}
            autoHeight={true}
            pagination
            paginationMode="server"
            loading={loading}
            getRowClassName={(params) =>
              `meeting-archive--${params.getValue(params.id, 'is_deleted')}`
            }
          />
        </div>
      </div>
    </div> */}
    </MainContainer >
  )
}
