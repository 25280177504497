import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const StateContext = createContext(null);

export async function login(token) {
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/login`, {
    method: "POST",
    body: JSON.stringify({ token }),
    headers: {
      "content-type": "application/json",
    },
  });
}

export default function AppStateProvider(props) {
  const history = useHistory();

  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRights, setUserRights] = useState([]);

  useEffect(() => {
    const api_key = cookies.get("d_key");
    const token = cookies.get("d_token");
    const user_attributes = cookies.get("u_attr");
    const getRoleName = cookies.get("role_rights");

    setUser(user_attributes);
    getRoleName?.length > 0 &&
      setUserRights(getRoleName?.map((data) => data?.name.toLowerCase()));
    /* const user = token
      ? { ...jwt_decode(token), attributes: user_attributes }
      : ""; */

    if (api_key && user_attributes && getRoleName && token) {
      setIsLoggedIn(true);
    } else {
      cookies.remove("d_token");
      setIsLoggedIn(false);
      history.replace("/");
    }
  }, [history]);

  const signIn = useCallback(async (token) => {
    return login(token).then(async (response) => {
      const jsonResponse = await response.json();

      if (response.ok && jsonResponse) {
        try {
          setIsLoggedIn(true);
          /* setUser({
            ...jwt_decode(token.credential),
            attributes: jsonResponse.user,
          }); */

          cookies.set("d_key", jsonResponse.d_key);
          cookies.set("mui_key", jsonResponse.mui_key);
          cookies.set("d_token", jsonResponse.accessToken);
          cookies.set("d_refresh_token", jsonResponse?.refreshToken);
          cookies.set("u_attr", jsonResponse?.userInfo);
          cookies.set("role_rights", jsonResponse?.user?.roles);
          const getUserDetails = cookies.get("u_attr");
          const getRoleName = cookies.get("role_rights");

          setUser(getUserDetails);
          getRoleName?.length > 0 &&
            setUserRights(getRoleName?.map((data) => data?.name.toLowerCase()));
          return { success: true };
          // resolve();
        } catch (error) {
          console.log(error, "Login Error");
          alert("Error while authenticating");
          return { success: false };
          // reject();
          // throw new Error("Error while authenticating");
        }
      } else {
        alert(
          jsonResponse.message
            ? jsonResponse.message
            : jsonResponse.error.message
            ? jsonResponse.error.message
            : "Error while authenticating"
        );
        return { success: false };
      }
    });
    // return new Promise((resolve, reject) => {
    //   try {
    //     setIsLoggedIn(true);
    //     setUser(jwt_decode(token.credential));
    //     cookies.set('d_token', token.credential);
    //     resolve();
    //   } catch (error) {
    //     console.log(error);
    //     alert("Error while authenticating");
    //     reject();
    //     // throw new Error("Error while authenticating");
    //   }
    // })
  }, []);

  const signOut = useCallback(() => {
    setUser(null);
    setIsLoggedIn(false);
    cookies.remove("d_token");
    cookies.remove("d_key");
    cookies.remove("mui_key");
    cookies.remove("u_attr");
    cookies.remove("d_refresh_token");
    cookies.remove("role_rights");
    history.replace("/");
  }, []);

  return (
    <StateContext.Provider
      value={{ user, userRights, isLoggedIn, signIn, signOut }}
    >
      {props.children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useAppState must be used within the AppStateProvider");
  }
  return context;
}
