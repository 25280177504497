import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

const MAIN_PRIMARY_COLOR = '#10416D'
const MAIN_SECONDARY_COLOR = '#ffffff'

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: MAIN_PRIMARY_COLOR,
    },
    secondary: {
      main: MAIN_SECONDARY_COLOR,
    },
    error: {
      main: red.A200,
    },
    background: {
      red: '#FFEAE9',
    },
  },
  typography: {
    fontFamily: [
      'Source Sans Pro',
      'sans-serif',
    ].join(','),
  },
  toolbar: {
    color: MAIN_PRIMARY_COLOR,
    backgroundColor: MAIN_SECONDARY_COLOR,
    display: 'flex',
    justifyContent: 'space-evenly',
    borderBottom: '1px solid #10416D',
    '& .logo': {
      flex: 2,
      alignItems: 'center',
      '& img': {
        maxWidth: '200px'
      }
    },
    '& .title': {
      flex: 2,
      textAlign: 'right',
      alignItems: 'center',
      '& .headerText': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'right',
        fontSize: '2.1875rem',
        fontWeight: 300,
        textTransform: 'uppercase',
        '& .headerIcon': {
          marginRight: '5px',
        }
      }
    }
  },
  actionButton: {
    backgroundColor: MAIN_PRIMARY_COLOR,
    color: MAIN_SECONDARY_COLOR,
    // lineHeight: 1,
    borderRadius: '10px',
    textTransform: 'none',
    margin: '5px',
    '&:disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: MAIN_PRIMARY_COLOR,
    }
  }
});

export default theme;