import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

// import './login.css';
import logo from "../images/logo.svg";
import { useAppState } from "../../state";

// import { makeStyles } from '@mui/styles';
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import { css } from "@emotion/react";
// import { styled } from '@mui/material/styles';

import Google_logo from "../images/google_logo.svg";
import styled from "@emotion/styled";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

const GridContainer = styled(Grid)(() => ({
  height: "100vh",
  justifyContent: "center",
}));

const GridItemImage = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.secondary.main,
  "& img": {
    maxWidth: "350px",
  },
}));

const GridItemPaper = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.primary.main,
  // padding: '0px 60px'
}));

// const useStyles = makeStyles((theme) => ({
//   root: {
//     height: '100vh',
//     justifyContent: 'center',
//   },
//   image: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     backgroundColor: theme.palette.secondary.main,
//     '& img': {
//       maxWidth: '350px'
//     }
//   },
//   paper: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     backgroundColor: theme.palette.primary.main,
//     // padding: '0px 60px'
//   }
// }));

function Login() {
  // const classes = useStyles();

  const { signIn } = useAppState();

  const history = useHistory();
  const location = useLocation();

  const handleCredentialResponse = async (codeResponse) => {
    const { code } = codeResponse;

    signIn(code).then((response) => {
      if (response.success) {
        history.replace(location?.state?.from || { pathname: `/` });
      }
    });
  };

  /*  useEffect(() => { */
  /* global google */
  /* google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleCredentialResponse,
    }); */

  /* google.accounts.id.renderButton(document.getElementById("gSigninButton"), {
      theme: "outline",
      size: "large",
    }); */
  /* }, []); */
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => handleCredentialResponse(codeResponse),
    flow: "auth-code",
  });

  return (
    <GridContainer container component="main">
      <CssBaseline />
      <GridItemImage item xs={false} sm={8} md={7}>
        <img src={logo} alt="" width="auto" height="120px" />
      </GridItemImage>
      <GridItemPaper
        item
        xs={12}
        sm={4}
        md={5}
        component={Paper}
        elevation={6}
        square
      >
        {/* <div id="gSigninButton"></div> */}
        <button
          style={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "white",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "5%",
          }}
          onClick={() => login()}
        >
          <img src={Google_logo} alt="google logo" style={{ width: "30px" }} />
          <p>Sign in with Google</p>
        </button>
      </GridItemPaper>
      {/* <div className='userlogin'>
        <div className="userLoginContainer">
          <div className="userloginLeft">
            <span className="logoadmin"><img src={logo} alt="" width="auto" height="120px" /></span>
          </div>
          <div className="userLoginRight">
            <div id="gSigninButton"></div>
          </div>
        </div>
      </div> */}
    </GridContainer>
  );
}

export default Login;
