// import './Interaction.css'
import React, { useState, useEffect, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import logo from "../images/logo.svg";
import axios from "../../helpers/request";
import moment from "moment";
import { useHistory } from "react-router-dom";

import {
  Container,
  Paper,
  Typography,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { TimelapseOutlined } from "@mui/icons-material";

import { DataGridPro } from "@mui/x-data-grid-pro";
// import { makeStyles } from '@mui/styles';

import styled from "@emotion/styled";
import { css } from "@emotion/react";

// import { TimelapseOutlined } from '@mui/icons-material';
// import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

// import { DataGrid } from '@mui/x-data-grid';
// import Grid from '@mui/material/Grid';

import { useAppState } from "../../state";
import InteractionIssue from "../InteractionIssue/InteractionIssue";

const SUPER_ADMIN_ROLE = "super_administrator";
const TRANSCRIPT_ROLE = "transcript";

const MainContainer = styled.main(({ theme }) => ({
  flexGrow: 1,
  height: "100vh",
  overflow: "auto",
  padding: theme.spacing(2),
}));

const Toolbar = styled.div(({ theme }) => theme.toolbar);

const ActionButton = styled(Button)(({ theme }) => theme.actionButton);

const InnerContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const DetailedTableContainer = styled(TableContainer)(({ theme }) => ({
  "&.MuiTableContainer-root": {
    overflow: "hidden",
  },
  "& .MuiTableCell-root": {
    padding: theme.spacing(1),
    borderBottom: "none",
  },
}));

const CustomTableLabel = styled(TableCell)(({ theme }) => ({
  whiteSpace: "nowrap",
  width: "30%",
}));

const CustomTableButton = styled(TableCell)(({ theme }) => ({
  width: "50%",
}));

const CustomPaper = styled(Paper)(({ theme }) => ({
  minHeight: "200px",
  "& .customRowTable": {
    "& .meeting-archive--true": {
      backgroundColor: "#FFEAE9",
    },
  },
  "& .Flip": {
    "& .MuiDataGrid-window": {
      transform: "rotateX(180deg)",
      top: "auto !important",
    },
    "& .MuiDataGrid-dataContainer": {
      transform: "rotateX(180deg)",
    },
  },
}));

const TitleSection = styled.div(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}));

const centerText = css({
  textAlign: "center",
});

const CenterTypography = styled(Typography)(() => ({
  textAlign: "center",
}));

const DangetText = styled.span(({ theme }) => ({
  color: theme.palette.error.main,
}));

// const useStyles = makeStyles((theme) => ({
//   content: {
//     flexGrow: 1,
//     height: '100vh',
//     overflow: 'auto',
//     padding: theme.spacing(2)
//   },
//   container: {
//     paddingTop: theme.spacing(4),
//     paddingBottom: theme.spacing(4),
//   },
//   toolbar: theme.toolbar,
//   actionButton: theme.actionButton,
//   tableHeader: {
//     margin: '0px'
//   },
//   paper: {
//     minHeight: '200px',
//     '& .customRowTable': {
//       '& .meeting-archive--true': {
//         backgroundColor: '#FFEAE9',
//       },
//     },
//     '& .Flip': {
//       '& .MuiDataGrid-window': {
//         transform: 'rotateX(180deg)',
//         top: 'auto !important'
//       },
//       '& .MuiDataGrid-dataContainer': {
//         transform: 'rotateX(180deg)'
//       }
//     }
//   },
//   detailedTable: {
//     '&.MuiTableContainer-root': {
//       overflow: 'hidden'
//     },
//     '& .MuiTableCell-root': {
//       padding: theme.spacing(1),
//       borderBottom: 'none'
//     }
//   },
//   tableLabel: {
//     whiteSpace: 'nowrap',
//     width: '30%'
//   },
//   tableButton: {
//     width: '50%'
//   },
//   sectionTitle: {
//     paddingLeft: theme.spacing(3),
//     paddingRight: theme.spacing(3),
//     paddingTop: theme.spacing(1),
//     paddingBottom: theme.spacing(1),
//   },
//   centerText: {
//     textAlign: 'center'
//   },
//   dangerText: {
//     color: theme.palette.error.main
//   }
// }));

export default function Interaction() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [meeting, setMeeting] = useState({});
  const { meetingId } = useParams();
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const { user, userRights } = useAppState();

  const TRANSCRIPT_USER = "transcripts team user";
  useEffect(() => {
    if (meetingId) {
      getMeeting(meetingId);
      getParticipants(meetingId);
    } else {
      history.replace("/");
    }
  }, [meetingId, history, count]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count + 1);
    }, 60000);

    return () => clearInterval(interval);
  }, [count]);

  useEffect(() => {
    if (userRights) {
      console.log("USER RIGHTS", userRights);
    }
  }, []);

  const getMeeting = (meetingId) => {
    axios.get("/api/meeting/" + meetingId).then(async (response) => {
      if (response && response.status === 200 && response.data) {
        setMeeting(response.data);
        // if (response.data.room_id) {
        //   getParticipants(response.data.room_id);
        // }
      } else {
        throw new Error(response?.error);
      }
    });
  };

  const getParticipants = (meetingId) => {
    let searchParams = {
      search: search,
    };
    axios
      .get("/internal/meeting/" + meetingId + "/participant")
      .then(async (response) => {
        if (response && response.status === 200) {
          // console.log(response.data);
          setData(response.data);
        } else {
          throw new Error(response?.error);
        }
      });
  };

  const downloadRecordingFile = (key, roomid) => {
    axios
      .get("/internal/recording/download", { params: { key, roomid } })
      .then(async (response) => {
        if (response && response.status === 200) {
          const link = document.createElement("a");
          link.target = "_blank";
          link.href = response.data.recording_file_url;
          // document.body.appendChild(link);
          link.click();
          // document.body.removeChild(link);
        } else {
          // console.log(response);
          alert("Unable to download recording file");
        }
      });
  };

  const downloadTranscriptFile = (key) => {
    axios
      .get("/api/recording/url", { params: { key } })
      .then(async (response) => {
        if (
          response &&
          response.status === 200 &&
          response.data &&
          response.data.transcript_status === "COMPLETED"
        ) {
          const link = document.createElement("a");
          link.target = "_blank";
          link.href = response.data.transcript_file_url;
          // document.body.appendChild(link);
          link.click();
          // document.body.removeChild(link);
        } else {
          // console.log(response);
          alert("Unable to download recording file");
        }
      });
  };

  const downloadFile = (keyType) => {
    const { meeting_id, room_id } = meeting;

    axios.get("/api/meeting/" + meeting_id + "/recordings").then(
      async (response) => {
        if (response && response.status === 200 && response.data.length) {
          const { key } = response.data[0];

          if (keyType === "recording") {
            downloadRecordingFile(key, room_id);
          } else if (keyType === "transcript") {
            downloadTranscriptFile(key);
          }
        } else {
          // console.log(response);
          alert("Unable to download recording file");
        }
      },
      async (error) => {
        // console.log(error?.response?.data?.message)
        alert(error?.response?.data?.message);
      }
    );
  };

  const columns = [
    // {
    //   field: 'email', headerName: 'Participant Email', width: 200
    // },
    // {
    //   field: 'phone_number', headerName: 'Participant Phone Number', width: 200, sortable: false
    // },
    {
      field: "issue_resolved",
      headerName: "Issue Status",
      width: 150,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {params.row.issue_reason !== null ? (
              <div>
                {params.row.issue_resolved === true ? (
                  <div
                    style={{
                      border: "1px solid transparent",
                      borderRadius: "10px",
                      padding: "6px 8px",
                      background: "#008100c9",
                      color: "white",
                    }}
                  >
                    Issue Resolved
                  </div>
                ) : (
                  <div
                    style={{
                      border: "1px solid transparent",
                      borderRadius: "10px",
                      padding: "6px 8px",
                      background: "#d84d4d",
                      color: "white",
                    }}
                  >
                    Issue Detected
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      field: "identity",
      headerName: "Participant Display Name",
      width: 200,
      // valueGetter: ({ value }) => value ? value.includes('+') ? value.split('###')[1].includes('##') ? value.split('###')[1].split('##')[1] : value.split('###')[1] : value.split('###')[0] : ''
      valueGetter: (params) => {
        if (!params.row.identity) {
          return "";
        }

        let append = "";
        if (
          (params.row.identity.split("###")[0] === "Chaperoning Mode" ||
            params.row.identity.split("###")[1].startsWith("CP##")) &&
          params.row.audio_enabled
        ) {
          append = " (unmuted)";
        }

        if (params.row.identity.includes("+")) {
          if (params.row.identity.split("###")[1].includes("##")) {
            return params.row.identity.split("###")[1].split("##")[1] + append;
          } else {
            return params.row.identity.split("###")[1] + append;
          }
        } else {
          return params.row.identity.split("###")[0] + append;
        }
      },
    },
    {
      field: "mode",
      headerName: "Mode",
      width: 120,
      sortable: false,
      valueGetter: (params) =>
        params.row.identity && params.row.identity.includes("###")
          ? params.row.identity.split("###")[0] === "Chaperoning Mode" ||
            params.row.identity.split("###")[1].startsWith("CP##")
            ? "Chaperone"
            : "Normal"
          : "",
    },
    {
      field: "join_time",
      headerName: "Join time (UTC)",
      width: 100,
      sortable: false,
      valueGetter: ({ value }) =>
        value ? moment(value).format("HH:mm") : "NA",
    },
    {
      field: "leave_time",
      headerName: "Leave time (UTC)",
      width: 100,
      sortable: false,
      valueGetter: ({ value }) =>
        value ? moment(value).format("HH:mm") : "NA",
    },
    {
      field: "join_method",
      headerName: "Join Via",
      width: 120,
      sortable: false,
    },
    { field: "device", headerName: "OS", width: 120, sortable: false },
    { field: "browser", headerName: "Browser", width: 120, sortable: false },
    { field: "location", headerName: "Location", width: 120, sortable: false },
    {
      field: "video",
      headerName: "Video Permission",
      width: 150,
      sortable: false,
      renderCell: (params) => {
        return <>{params.row.video ? <b>Yes</b> : "No"}</>;
      },
    },
    {
      field: "audio",
      headerName: "Audio Permission",
      width: 150,
      sortable: false,
      renderCell: (params) => {
        return <>{params.row.audio ? <b>Yes</b> : "No"}</>;
      },
    },
    {
      field: "screen_share",
      headerName: "Screen Sharing",
      width: 150,
      sortable: false,
      renderCell: (params) => {
        return <>{params.row.screen_share ? <b>Yes</b> : "No"}</>;
      },
    },
    {
      field: "kill_switch_used",
      headerName: "Kill Switch Used",
      width: 150,
      sortable: false,
      renderCell: (params) => {
        const ksv = params.value;
        if (!params.row.identity) {
          return "";
        }

        const identity = params.row.identity.split("###")[0] || "";
        const identifier = params.row.identity.split("###")[1] || "";

        if (ksv === true) {
          return <b>Yes</b>;
        } else if (
          identity === "Chaperoning Mode" ||
          identifier.startsWith("CP##")
        ) {
          return "No";
        } else {
          return "-";
        }
      },
    },
  ];

  /**
   * function for open issue type and reason details
   */
  const getDetailPanelContent = useCallback(
    ({ row }) => row.issue_reason && <InteractionIssue row={row} />,
    []
  );

  return (
    <MainContainer>
      <Toolbar>
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <div className="title">
          <span className="headerText">
            <TimelapseOutlined fontSize="large" className="headerIcon" />
            View Interaction
          </span>
        </div>
      </Toolbar>

      <TitleSection>
        <Link to={"/"}>
          <ActionButton>
            {/* <KeyboardBackspace></KeyboardBackspace> */}
            Back
          </ActionButton>
        </Link>
        <CenterTypography variant="h6">Interaction Overview</CenterTypography>
      </TitleSection>

      <InnerContainer maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12} md={3} lg={3} xl={3}>
            <DetailedTableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <CustomTableLabel>Twilio ID :</CustomTableLabel>
                    <TableCell>{meeting.meeting_id}</TableCell>
                  </TableRow>
                  <TableRow>
                    <CustomTableLabel>Topic :</CustomTableLabel>
                    <TableCell>{meeting.topic}</TableCell>
                  </TableRow>
                  <TableRow>
                    <CustomTableLabel>Maven ID :</CustomTableLabel>
                    <TableCell>{meeting.maven_id}</TableCell>
                  </TableRow>
                  <TableRow>
                    <CustomTableLabel>Maven Source :</CustomTableLabel>
                    <TableCell>{meeting.source}</TableCell>
                  </TableRow>
                  <TableRow>
                    <CustomTableLabel>Participants :</CustomTableLabel>
                    <TableCell>{data.length}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </DetailedTableContainer>
            {/* <Paper className={classes.paper}>
            <List >
                <ListItem>
                  Twilio ID : {meeting.meeting_id}
                </ListItem>
                <ListItem>
                  Topic : {meeting.topic}
                </ListItem>
                <ListItem>
                  Maven ID : {meeting.maven_id}
                </ListItem>
                <ListItem>
                  Participants : {data.length}
                </ListItem>
              </List>
            </Paper> */}
          </Grid>
          <Grid item xs={12} md={3} lg={3} xl={3}>
            <DetailedTableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <CustomTableLabel>Meeting date :</CustomTableLabel>
                    <TableCell>
                      {moment(meeting.datetime).format("DD/MM/YYYY")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <CustomTableLabel>Start time (UTC) :</CustomTableLabel>
                    <TableCell>
                      {meeting.started_at
                        ? moment(meeting.started_at).format("HH:mm")
                        : "NA"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <CustomTableLabel>End time (UTC) :</CustomTableLabel>
                    <TableCell>
                      {meeting.ended_at
                        ? moment(meeting.ended_at).format("HH:mm")
                        : "NA"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <CustomTableLabel>Duration :</CustomTableLabel>
                    <TableCell>
                      {meeting.ended_at && meeting.started_at
                        ? meeting.meeting_ended_at
                          ? Math.ceil(
                              moment(meeting.meeting_ended_at).diff(
                                moment(meeting.started_at),
                                "minutes",
                                true
                              )
                            ) + " minute(s)"
                          : Math.ceil(
                              moment(meeting.ended_at).diff(
                                moment(meeting.started_at),
                                "minutes",
                                true
                              )
                            ) + " minute(s)"
                        : "NA"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </DetailedTableContainer>
          </Grid>
          <Grid item xs={12} md={3} lg={3} xl={3}>
            <DetailedTableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <CustomTableLabel>Recording :</CustomTableLabel>
                    <TableCell>
                      {meeting.record_meeting ? "Yes" : "No"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <CustomTableLabel>Anonymous :</CustomTableLabel>
                    <TableCell>{meeting.is_anonymous ? "Yes" : "No"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <CustomTableLabel>Disclaimer :</CustomTableLabel>
                    <TableCell>
                      {meeting.show_disclaimer ? "Yes" : "No"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <CustomTableLabel>Archived | Deleted :</CustomTableLabel>
                    <TableCell>
                      {meeting.is_archived ? (
                        <DangetText>Yes </DangetText>
                      ) : (
                        "No "
                      )}
                      |
                      {meeting.is_deleted ? (
                        <DangetText> Yes</DangetText>
                      ) : (
                        " No"
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </DetailedTableContainer>
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={3}>
            <DetailedTableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <CustomTableButton>
                      <ActionButton
                        onClick={
                          meeting.record_meeting &&
                          userRights.length > 0 &&
                          userRights.includes(TRANSCRIPT_USER)
                            ? () => downloadFile("recording")
                            : () => {}
                        }
                        disabled={
                          !meeting.record_meeting ||
                          (userRights.length > 0 &&
                            !userRights.includes(TRANSCRIPT_USER))
                        }
                      >
                        Recording
                      </ActionButton>
                    </CustomTableButton>
                    <CustomTableButton>
                      {meeting.started_at && !meeting.ended_at ? (
                        <ActionButton href={meeting.join_link} target="_blank">
                          Join Meeting
                        </ActionButton>
                      ) : (
                        <ActionButton disabled={true}>
                          Join Meeting
                        </ActionButton>
                      )}
                    </CustomTableButton>
                  </TableRow>
                  <TableRow>
                    <CustomTableButton>
                      <ActionButton
                        onClick={
                          meeting.record_meeting &&
                          userRights.length > 0 &&
                          userRights.includes(TRANSCRIPT_USER)
                            ? () => downloadFile("transcript")
                            : () => {}
                        }
                        disabled={
                          !meeting.record_meeting ||
                          (userRights.length > 0 &&
                            !userRights.includes(TRANSCRIPT_USER))
                        }
                      >
                        Transcript
                      </ActionButton>
                    </CustomTableButton>
                    <CustomTableButton>
                      {meeting.started_at && !meeting.ended_at ? (
                        <a href={meeting.chaperoning_link} target="_blank">
                          <ActionButton>Chaperone Meeting</ActionButton>
                        </a>
                      ) : (
                        <ActionButton disabled={true}>
                          Chaperone Meeting
                        </ActionButton>
                      )}
                    </CustomTableButton>
                  </TableRow>
                </TableBody>
              </Table>
            </DetailedTableContainer>
          </Grid>
          <Grid item xs={12}>
            <TitleSection>
              <CenterTypography variant="h6">
                Participant Overview
              </CenterTypography>
            </TitleSection>
          </Grid>
          <Grid item xs={12}>
            <CustomPaper>
              <div className="customRowTable">
                <div className="Flip">
                  <DataGridPro
                    rows={data}
                    columns={columns}
                    pageSize={25}
                    rowsPerPageOptions={[10, 25, 50]}
                    disableSelectionOnClick
                    autoHeight={true}
                    getDetailPanelContent={getDetailPanelContent}
                    getDetailPanelHeight={() => "auto"}
                  />
                </div>
              </div>
            </CustomPaper>
          </Grid>
        </Grid>
      </InnerContainer>
      {/* <div className='meetings'>
      <div className="pageHeader">
        <div className="headerLeft">
          <img src={logo} alt="" />
        </div>
        <div className="headerRight">
          <span className="pageHeaderTitle">
            <TimelapseOutlined className='pageHeaderIcon' />View Interaction
          </span>
        </div>
      </div>
      <Grid container className={classes.tableHeader} spacing={3}>
        <Grid item xs={12} sm={2}>
          <div>
            <Link to={'/'}>
              <button className="actionElementButton">
                <KeyboardBackspace></KeyboardBackspace>
                <span >Back</span>
              </button>
            </Link>
          </div>
        </Grid>
      </Grid>

      <div className='title'>Interaction Overview</div>

      <div className="meetingparentdetails">
        <Grid container spacing={1}>
          <Grid container item xs={4} spacing={1} >
            <table>
              <tbody>
                <tr>
                  <td><span className='p-r-20' >Twilio ID</span></td>
                  <td>{meeting.meeting_id}</td>
                </tr>
                <tr>
                  <td><span className='p-r-20'>Topic</span></td>
                  <td>{meeting.topic}</td>
                </tr>
                <tr>
                  <td><span className='p-r-20'>Maven ID</span></td>
                  <td>{meeting.maven_id}</td>
                </tr>
                <tr>
                  <td><span className='p-r-20'>Participants</span></td>
                  <td>{data.length}</td>
                </tr>
              </tbody>
            </table>
          </Grid>
          <Grid container item xs={3} spacing={1} >
            <table>
              <tbody>
                <tr>
                  <td><span className='p-r-20'>Meeting date</span></td>
                  <td>{moment(meeting.datetime).utc().format('YYYY/MM/DD')}</td>
                </tr>
                <tr>
                  <td><span className='p-r-20'>Start time (UTC)</span></td>
                  <td>{meeting.started_at ? moment(meeting.started_at).utc().format('HH:mm') : "NA"}</td>
                </tr>
                <tr>
                  <td><span className='p-r-20'>End time (UTC)</span></td>
                  <td>{meeting.ended_at ? moment(meeting.ended_at).utc().format('HH:mm') : "NA"}</td>
                </tr>
                <tr>
                  <td><span className='p-r-20'>Duration</span></td>
                  <td>{(meeting.ended_at && meeting.started_at) ? moment(meeting.ended_at).diff(moment(meeting.started_at), 'minutes') + ' minute(s)' : "NA"}</td>
                </tr>
              </tbody>
            </table>
          </Grid>
          <Grid container item xs={2} spacing={1} >
            <table>
              <tbody>
                <tr>
                  <td><span className='p-r-20'>Recording</span></td>
                  <td>{meeting.record_meeting ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                  <td><span className='p-r-20'>Anonymous</span></td>
                  <td>{meeting.is_anonymous ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                  <td><span className='p-r-20'>Disclaimer</span></td>
                  <td>{meeting.show_disclaimer ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                  <td><span className='p-r-20'>Archived | Deleted</span></td>
                  <td> 
                    <span className={meeting.is_archived ? "color-red" : ""}>{meeting.is_archived ? 'Yes ' : 'No '}</span>|
                    <span className={meeting.is_deleted ? "color-red" : ""}>{meeting.is_deleted ? ' Yes' : ' No'}</span> 
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
          <Grid container item xs={3} spacing={1} >
            <table>
              <tbody>
                <tr>
                  <td>
                    <button onClick={meeting.record_meeting ? () => downloadFile('recording') : () => { }} disabled={!meeting.record_meeting} type='button' className="actionElementButton">
                      <span>Recording</span>
                    </button>
                  </td>
                  <td>
                    {
                      (meeting.started_at && !meeting.ended_at) ?
                        <a href={meeting.join_link} className='link' target="_blank" >
                          <button type='button' className="actionElementButton">
                            <span>Join Meeting</span>
                          </button>
                        </a>
                        :
                        <button disabled={true} type='button' className="actionElementButton">
                          <span>Join Meeting</span>
                        </button>
                    }
                  </td>
                </tr>
                <tr>
                  <td>
                    <button type='button' onClick={meeting.record_meeting ? () => downloadFile('transcript') : () => { }} disabled={!meeting.record_meeting} className="actionElementButton">
                      <span>Transcript</span>
                    </button>
                  </td>
                  <td>
                    {
                      (meeting.started_at && !meeting.ended_at) ?
                        <a href={meeting.chaperoning_link} className='link' target="_blank" >
                          <button type='button' className="actionElementButton">
                            <span>Chaperone Meeting</span>
                          </button>
                        </a>
                        :
                        <button disabled={true} type='button' className="actionElementButton">
                          <span>Chaperone Meeting</span>
                        </button>
                    }
                  </td>
                </tr>
              </tbody>
            </table>

          </Grid>
        </Grid>
      </div>
      <div className='title'>Participant Overview</div>
      <div className="meetingparentdetails">
        <div className="Child">
          <div className="childdetails">
            <div className="childtable">
              <div className='Flip'>
                <DataGrid
                  rows={data}
                  columns={columns}
                  pageSize={25}
                  rowsPerPageOptions={[10, 25, 50]}
                  disableSelectionOnClick
                  autoHeight={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
    </MainContainer>
  );
}
